.home-footer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  background: #008080;
  color: white;
  font-size: clamp(1.5rem * 0.5, 1.5vw, 1.15rem);
  width: 100%;
}

.col-right {
  padding: 32px 0;
  width: 60%;
  @media (min-width: 1999px) {
    width: 40%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.col-right p{
  margin: 0;
}

.footer-links {
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 20%;
  gap: 16px;
  text-align: left;
}

.footer-links p {
  margin: 0;
  width: min-content;
  font-weight: 700;
  text-decoration: underline;
}

.footer-links a {
  text-align: start;
  text-decoration: none;
  color: inherit;
}

.social-icon-row {
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.social-icon {
  cursor: pointer;
  width: 40px;
}
