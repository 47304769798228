.our-story {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 64px;
}

.our-story-content {
  width: 60%;
  @media (min-width: 1999px) {
    width: 40%;
  }
  color: black;
  text-align: justify;
  font-size: clamp(1.5rem * 0.5, 1.5vw, 1.15rem);
}
