.main-header {
  height: min-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
}

.headline{
  padding: 16px 0;
  width: 100%;
  color: white;
  background-color: #008080;
  font-size: clamp(1rem * 0.7, 1.75vw, 1rem);
}

.headline p{
  margin: 0;
  padding: 0 8px;
}

.side-bar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
  height: 100vh;
  width: 100%;
}


.side-bar {
  z-index: 1;
  background-color: #06AA90;
  color: black;
  display: flex;
  position: fixed;
  top: 0;
  left: -400px;
  flex-direction: column;
  align-items: center;
  width: 40vw;
  height: 100vh;
  transition: left 0.3s ease-in-out;
  border-radius: 0 8px 8px 0;
}

.side-bar.open {
  left: 0;
}

.side-bar p {
  padding: 18px 0;
  font-size: clamp(1.375rem * 0.75, 1.5vw, 1.375rem);
  cursor: pointer;
}

.nav-bar {
  background-color: white;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 64px;
  color: #008080;
  display: none;
  @media (min-width: 1000px) {
    display: flex;
  }
}

.nav-bar p {
  font-size: clamp(1.375rem * 0.75, 1.15vw, 1rem);
  cursor: pointer;
  font-weight: 600;
}

.header-banner {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding-top: 16px;
  background-color: white;
}

.header-banner img {
  max-width: 210px;
  padding: 16px 0;
}

.close-icon{
  align-self: flex-end;
  padding: 8px 8px 0 0;
}

.header-divider{
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
  margin: 1px;
  width: 100%;
}