.contact-us{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 64px;
}

.contact-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0;
    gap: 16px;
}

.contact-form input{
    width: 80vw;
    max-width: 700px;
    font-size: clamp(1.5rem * 0.5, 1.5vw, 1.15rem);
    font-family: "Ronnia";
}

.contact-form textarea{
    font-family: "Ronnia";
    width: 80vw;
    max-width: 700px;
    font-size: clamp(1.5rem * 0.5, 1.5vw, 1.15rem);
}

input::placeholder {
    font-weight: bold;
    font-size: clamp(1.5rem * 0.5, 1.5vw, 1.15rem);
    opacity: 0.6;
}

textarea::placeholder {
    font-weight: bold;
    opacity: 0.6;
    font-family: "Ronnia";
    font-size: clamp(1.5rem * 0.5, 1.5vw, 1.15rem);
}