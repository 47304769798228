.roles-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    @media (max-width: 499px) {
      gap: 16px;
    }
    padding: 32px 0;
    gap: 24px;
}

.role{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 32px 0;
}

.rolecol{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 70%;
  padding: 32px 0 0 0;
}

.role-title{
    text-align: start;
    margin: 8px 0 0 100px;
    @media (max-width: 999px) {
      margin: 8px 0;
    }
    flex: 1;
    @media (max-width: 499px) {
      gap: 16px;
    }
    font-size: clamp(1rem, 2.25vw, 2.25rem);
}

.role-description{
    display: flex;
    flex-direction: column;
    flex: 2;
    gap: 8px;
    align-items: flex-start;
}
.role-description-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    color: black;
    margin: 32px 0;
    font-size: clamp(1rem * 0.75, 1.5vw, 1.75rem);
}

.role-description-icon{
  min-width: 48px;
  min-height: 48px;
  width: 6vw;
  height: 6vw;
}


.role-description-item p{
  margin: 0;
  padding: 0;
}

.role-divider{
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
  margin: 1px;
  width: 90%;
  @media (max-width: 999px) {
    width: 72vw;
  }
}