.screenshot-slider-container {
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;
    background: #bafcf1;
  }

  .screenshot-slider::-webkit-scrollbar {
    height: 8px; /* Set the width of the scrollbar */
  }

  .screenshot-slider::-webkit-scrollbar-track {
    background: #f1f1f1; /* Set the background color of the scrollbar track */
  }

  .screenshot-slider::-webkit-scrollbar-thumb {
    background: #888; /* Set the color of the scrollbar thumb */
  }

  .screenshot-slider::-webkit-scrollbar-thumb:hover {
    background: #555; /* Set the color of the scrollbar thumb on hover */
  }

  .screenshot{
    max-width: 100%; /* Set a maximum width for the images */
    max-height: 50vh;
  }

  @media (min-width: 1000px) {
    .screenshot{
      max-width: 1000px;
      max-height: 70vh;
    }
  }

  @media (min-width: 600px) {
    .screenshot{
      max-width: 600px;
      max-height: 60vh;
    }
  }
