.showcase-products-container {
  display: flex;
  flex-direction: column;
  @media (min-width: 999px) {
    margin-top: 32px;
  }
  @media (max-width: 499px) {
    gap: 16px;
  }
  padding: 16px 0 0 0;
  gap: 24px;
  background-color: white;
  width: 100%;
  color: #008080;
}

.showcase-title{
  padding: 32px 16px;
  @media (max-width: 499px) {
    padding: 16px 16px;
  }
}

.showcase-products {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 16px;
  justify-content: space-around;
  padding: 32px 16px;
}

.showcase-product {
  width: 20vw;
  height: 20vw;
  @media (max-width: 799px) {
    width: 25vw;
    height: 25vw;
  }
  @media (max-width: 499px) {
    width: 36vw;
    height: 36vw;
  }
}
