.paynow-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    font-size: clamp(1rem, 2.25vw, 2.25rem);
    padding-top: 200px;
  }

  .paynow-page p{
    font-weight: 700;
    text-align: center;
    width: 80%;
  }

  .paynow-page button {
    margin: 0;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    background-color: #06aa90;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    font-size: 1em;
  }
