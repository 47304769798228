.app {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  justify-content: space-between;
  font-size: clamp(1rem, 2.25vw, 2.25rem);
  background-color: white;
  color: #008080;
  width: 100%;
  font-weight: 600;
}

.error-page {
  display: flex;
  text-align: center;
  flex-direction: column;
  font-size: clamp(1rem, 2.25vw, 2.25rem);
  background-color: white;
  color: #008080;
  width: 100%;
  font-weight: 600;
}

.app-preview {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  gap: 16px;
  min-height: 100vh;
  background-color: #f2f2f2;
  color: #2a2a2a;
  width: 100%;
}

h1 {
  font-size: clamp(1rem, 2.25vw, 2.25rem);
  font-weight: 600;
}

.app-logo-sqr {
  padding-top: 16px;
  height: 128px;
  width: 128px;
  pointer-events: none;
}

.t-and-c-page {
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 80%;
  font-size: clamp(1.5rem * 0.5, 1.5vw, 1.15rem);
  text-align: justify;
  color: black;
}

.t-and-c-page p {
  margin: 8px 0;
}

.divider{
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
  margin: 1px;
  width: 90%;
}

/* FOR PAGES THAT A NOT COMPLETE
.under-construction-logo {
  padding: 44px;
  height: 16vmin;
  pointer-events: none;
}
*/
