.seller-tile-wrapper {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin: 4px;
}

.seller-tile {
  padding: 16px;
  gap: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  min-height: 100px;
  @media (max-width: 549px) {
    gap: 8px;
    min-height: 64px;
  }
}

.seller-title {
  display: flex;
  text-align: start;
  margin: 0px;
  font-weight: 700;
  width: 42vw;
  font-size: 0.64em;
}
