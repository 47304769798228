.product-preview {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background-color: #f2f2f2;
  }

  .product-offers-list {
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .product-large-img {
    height: 25vh;
  }