.preview-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  margin-top: 10vh;
  margin-bottom: 15vmin;
  align-items: center;
  @media (max-width: 499px) {
    margin-bottom: 19vmin;
  }
}

.preview-title {
  margin: 0;
  font-size: clamp(2.5rem * 0.5, 2.5vw, 3.5rem);
  font-weight: 700;
}
