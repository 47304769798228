.preview-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding-top: 4px;
  padding-bottom: 4px;
  height: 8vh;
  @media (max-width: 499px) {
    height: 7vh;
  }
}

.icon{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  height: 2rem;
  font-size: 0.64em;
}

.icon p{
  margin: 2px;
}
