.preview-header {
  display: flex;
  text-align: center;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: #06AA90;
  width: 100%;
  height: 9vh;
  position: fixed;
  top: 0;
  left: 0;
}

.preview-header-logo{
  height: 6vh;
  padding-right: 1rem;
}
