.faq {
  width: 80%;
  padding-bottom: 64px;
}

.faq-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: start;
  font-size: clamp(1.5rem * 0.5, 1.5vw, 1.15rem);
}

.faq-section-head {
  font-size: clamp(1.5rem * 0.5, 1.75vw, 1.5rem);
  font-weight: 700;
  text-decoration: underline;
}

.faq-section-question {
  margin: 0;
  font-weight: 700;
}

.faq-section-answer {
  margin: 0 0 16px 0;
  color: black;
}
