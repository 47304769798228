.offer-tile-wrapper {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin: 4px;
}

.offer-tile {
  padding: 16px;
  gap: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  @media (max-width: 549px) {
    gap: 8px;
  }
}

.seller-display {
  display: flex;
  flex-direction: column;
  margin: 0px;
  align-items: center;
  width: 12vw;
  font-size: 0.64em;
}

.seller-img {
  width: 16vw;
  max-width: 96px;
  @media (max-width: 549px) {
    width: 12vw;
  }
}

.offer-title {
  display: flex;
  text-align: start;
  margin: 0px;
  font-weight: 700;
  width: 42vw;
  font-size: 0.64em;
}

.offer-info {
  display: flex;
  margin: 0px;
  width: 42vw;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.64em;
}

.offer-description {
  margin: 0px;
  width: 42vw;
  overflow-wrap: normal;
  text-align: start;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  font-size: 0.64em;
}

.products-list {
  padding: 8px;
  width: 25vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.64em;
}

.products-list p {
  margin: 1px;
  width: 96%;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.offers-button {
  margin: 0;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-color: #06aa90;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  font-size: 0.64em;
}

.offers-button p {
  margin: 8px;
  font-weight: 700;
}

.button-row {
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
  justify-content: space-evenly;
}
