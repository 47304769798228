.product-tile {
  padding: 16px 0;
  margin: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 96vw;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.product-img {
  width: 20vw;
  max-width: 128px;
}

.product-title {
  display: flex;
  text-align: start;
  margin: 0px;
  font-size: 0.7em;
  font-weight: 700;
  width: 50vw;
}

.product-info {
  display: flex;
  margin: 0px;
  font-size: 0.64em;
  width: 50vw;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.product-description {
  margin: 0px;
  font-size: 0.64em;
  width: 50vw;
  overflow-wrap: normal;
  text-align: start;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.details-button {
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-color: #06aa90;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.details-button p {
  margin: 0;
  font-size: 0.64em;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.overlay-content {
  width: 90%;
  max-width: 90vw;
}

.overlay-content img {
  max-width: 90%;
  max-height: 90vh;
  border-radius: 4px;
}
