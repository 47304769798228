.product-preview-sheet {
  display: flex;
  flex-direction: column;
  background: white;
  justify-content: flex-start;
  padding: 16px 0;
  border-radius: 32px 32px 0 0;
  gap: 8px;
  width: 100%;
}

.back-button {
  cursor: pointer;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: min-content;
}

.product-name {
  margin: 0;
  padding: 0 16px;
  text-align: left;
  font-weight: 600;
  color: #06aa90;
}

.info-row {
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.info-row-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  gap: 8px;
  flex: 1;
}

.info-row-item p {
  margin: 0;
}

.para-text {
  padding: 0 16px;
  margin: 2px;
  text-align: left;
}
