.app-store-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 32px;
  @media (max-width: 767px) {
    gap: 8px;
  }
  width: 100%;
  color: white;
  background-color: black;
}

.app-store-links-modal {
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 32px;
  @media (max-width: 767px) {
    gap: 8px;
  }
}

.app-store-button-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10vw;
  padding-bottom: 16px;
}

.app-store-button {
  cursor: pointer;
  outline: none;
}

.app-store-button-image {
  width: 300px;
  height: 92px;
  border-radius: 8px;
  border: 1px solid white;
  @media (max-width: 1599px) {
    width: 150px;
    height: 46px;
  }
  @media (max-width: 767px) {
    width: 100px;
    height: 32px;
  }
}
