.dots-slider {
  position: relative;
  width: 100%;
  height: 40vw;
  max-height: 800px;
  @media (min-width: 1999px) {
    height: 30vw;
  }
}

.dots-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: min-content;
  width: 100%;
  height: 40vw;
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
  @media (min-width: 1999px) {
    height: 30vw;
  }
}

.dots-slide.active {
  opacity: 1;
}

.slider-overlay {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.dots-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 2px solid #333;
  cursor: pointer;
}

.dot.active {
  background-color: #333;
}

.preview-app-button {
  cursor: pointer;
  background-color: white;
  color: black;
  padding: 8px 16px;
  width: min-content;
  white-space: nowrap;
  font-size: clamp(1rem * 0.5, 1.5vw, 2rem);
  border: 1px solid black;
  border-radius: 100px;
  @media (max-width: 600px) {
    padding: 4px 8px;
  }
  opacity: 0.7;
}
