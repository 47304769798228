.suppliers {
  display: flex;
  flex-direction: column;
  @media (max-width: 499px) {
    gap: 16px;
  }
  padding-bottom: 32px;
  gap: 24px;
  width: 100%;
}

.suppliers p{
  padding: 32px 16px;
  @media (max-width: 499px) {
    padding: 16px 16px;
  }
}

.suppliers-logo-display {
  padding: 32px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.suppliers-logo {
  display: flex;
  max-width: 96vw;
  height: 256px;
  @media (max-width: 1999px) {
    height: 128px;
  }
  @media (max-width: 649px) {
    height: 96px;
  }
  @media (max-width: 499px) {
    height: 80px;
  }
}

.suppliers-logo-heinrich {
  padding: 32px 0;
  display: flex;
  max-width: 96vw;
  height: 128px;
  @media (max-width: 1999px) {
    height: 64px;
  }
  @media (max-width: 649px) {
    height: 50px;
  }
  @media (max-width: 499px) {
    height: 40px;
  }
}
