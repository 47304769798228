.tracking-bar {
  position: sticky;
  background-color: white;
  top: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  padding: 16px 0;
  font-size: clamp(3.5rem * 0.5, 2vw, 3.5rem);
}

.tracking-toggle {
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding-bottom: 5px;
  margin-right: 10px;
  color: black;
}

.tracking-toggle.active {
  color: #008080;
}

.tracking-toggle::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #06AA90;
  transition: transform 0.3s ease;
  transform: scaleX(0);
  transform-origin: left;
}

.tracking-toggle.active::before {
  transform: scaleX(1);
}
